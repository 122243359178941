//doesn't need the auth data, can retrieve it from index.js
//need to be some way to throw/error if not authenticated. maybe just return false.

import { Auth } from '../../store'

const baseURL = process.env.REACT_APP_BASE_URL

const fetchData = async (number: number, authData: Auth) => {
  const response = await fetch(
    `${baseURL}/student/${number}?room=${authData.username}`,
    {
      headers: { Authorization: `Bearer ${authData.token}` },
    },
  )
  if (response.ok) {
    const body = await response.json()
    return {
      name: 'John Doe',
      photo: '',
      status: body.status,
      statusText: body.message,
      statusToken: body.token,
    }
  } else {
    throw response
  }
}

/*
Fetching inputs the auth data component, the number to fetch
Fetching returns a name, photo, status, and optional status text.
Or fetching can throw an error.
This module should also submit the data to Attendance in one go. then we don't have to do any of that in the platform-agnostic code.
*/

const validate = (number: string) => {
  return /^\d{6,7}$/.test(number)
}

const checkin = async (number: number, statusToken: string, authData: Auth) => {
  const response = await fetch(
    `${baseURL}/checkin/${number}/${authData.username}`,
    { method: 'POST', headers: { Authorization: `Bearer ${statusToken}` } },
  )
  if (!response.ok) {
    throw response
  }
}
/*
this function determines if a code is valid (to be ran before fetching)
*/

/*this function returns the max amount of characters
 */

const getRequestsByRoom = async (
  authData: Auth,
): Promise<Array<{ room: string; student: string; date: string }>> => {
  const response = await fetch(`${baseURL}/request?room=${authData.username}`, {
    headers: { Authorization: `Bearer ${authData.token}` },
  })
  if (response.ok) {
    const body = await response.json()
    return body
  } else {
    throw response
  }
}

const deleteRequest = async (date: string, student: string, authData: Auth) => {
  const response = await fetch(`${baseURL}/request/${date}/${student}`, {
    method: 'DELETE',
    headers: { Authorization: `Bearer ${authData.token}` },
  })
  if (!response.ok) {
    throw response
  }
}

const createRequest = async (date: string, student: string, authData: Auth) => {
  const response = await fetch(`${baseURL}/request/${date}/${student}`, {
    method: 'POST',
    headers: { Authorization: `Bearer ${authData.token}` },
    body: JSON.stringify({ room: authData.username }),
  })
  if (!response.ok) {
    throw response
  }
}

const characterCutoff = 7

export {
  fetchData,
  validate,
  checkin,
  characterCutoff,
  getRequestsByRoom,
  deleteRequest,
  createRequest,
}
