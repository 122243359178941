import { Button } from '..'
const Keypad = ({
  input,
  confirm,
  backspace,
  canInput,
  canProceed,
  canConfirm,
  canClear,
  canBackspace,
}: {
  input: (number: number) => void
  confirm: () => void
  backspace: () => void
  canInput: boolean
  canProceed: boolean
  canConfirm: boolean
  canClear: boolean
  canBackspace: boolean
}) => {
  return (
    <div
      style={{ position: 'absolute', bottom: 0, height: '44%', width: '100%' }}
    >
      <div
        style={{
          width: '100%',
          height: 'calc(100% / 4)',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button
          content="1"
          action={() => {
            input(1)
          }}
          disabled={!canInput}
        />
        <Button
          content="2"
          action={() => {
            input(2)
          }}
          disabled={!canInput}
        />
        <Button
          content="3"
          action={() => {
            input(3)
          }}
          disabled={!canInput}
        />
      </div>
      <div
        style={{
          width: '100%',
          height: 'calc(100% / 4)',
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '1px',
        }}
      >
        <Button
          content="4"
          action={() => {
            input(4)
          }}
          disabled={!canInput}
        />
        <Button
          content="5"
          action={() => {
            input(5)
          }}
          disabled={!canInput}
        />
        <Button
          content="6"
          action={() => {
            input(6)
          }}
          disabled={!canInput}
        />
      </div>
      <div
        style={{
          width: '100%',
          height: 'calc(100% / 4)',
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '1px',
        }}
      >
        <Button
          content="7"
          action={() => {
            input(7)
          }}
          disabled={!canInput}
        />
        <Button
          content="8"
          action={() => {
            input(8)
          }}
          disabled={!canInput}
        />
        <Button
          content="9"
          action={() => {
            input(9)
          }}
          disabled={!canInput}
        />
      </div>
      <div
        style={{
          width: '100%',
          height: 'calc(100% / 4)',
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '1px',
        }}
      >
        <Button
          content={
            canClear ? (
              <i className="material-icons">close</i>
            ) : (
              <i className="material-icons">backspace</i>
            )
          }
          action={backspace}
          disabled={!canBackspace && !canClear}
        />
        <Button
          content="0"
          action={() => {
            input(0)
          }}
          disabled={!canInput}
        />
        <Button
          content={
            canConfirm ? (
              <i className="material-icons">check</i>
            ) : (
              <i className="material-icons">arrow_forward</i>
            )
          }
          action={confirm}
          disabled={!canConfirm && !canProceed}
          green={canConfirm}
        />
      </div>
    </div>
  )
}

export default Keypad
