import { getRequestsByRoom, deleteRequest, createRequest } from '../../api'
import { useEffect, useState, useContext, useCallback, Fragment } from 'react'
import { storeContext } from '../../store'
import { useHistory } from 'react-router'

const TeacherRequests = () => {
  const [requests, setRequests] = useState<
    Array<{ date: string; students: Set<string> }>
  >([])
  const { auth } = useContext(storeContext)
  const [creating, setCreating] = useState(false)
  const [date, setDate] = useState('')
  const [student, setStudent] = useState('')
  const history = useHistory()

  const fetchData = useCallback(async () => {
    if (auth) {
      const requests = await getRequestsByRoom(auth)
      const keyedDates = requests.reduce(
        (acc: { [key: string]: Set<string> }, request) => {
          const set = acc[request.date] || new Set()
          return {
            ...acc,
            [request.date]: set.add(request.student),
          }
        },
        {},
      )
      const sortedData = Object.entries(keyedDates)
        .map(([date, students]) => {
          return {
            date,
            students,
          }
        })
        .sort((a, b) => (a.date < b.date ? -1 : a.date > b.date ? 1 : 0))
      setRequests(sortedData)
    }
  }, [auth])
  useEffect(() => {
    fetchData()
  }, [fetchData])
  return (
    <div style={{ padding: '10px' }}>
      <h3>Outstanding Requests</h3>
      {requests.map((request) => (
        <Fragment key={request.date}>
          <b>{request.date}</b>
          <ul>
            {Array.from(request.students).map((student) => (
              <li key={student}>
                {student}{' '}
                <button
                  onClick={async () => {
                    if (auth) {
                      await deleteRequest(request.date, student, auth)
                      await fetchData()
                    }
                  }}
                >
                  X
                </button>
              </li>
            ))}
          </ul>
        </Fragment>
      ))}
      {creating ? (
        <>
          <input
            type="date"
            onChange={(event) => {
              setDate(event.target.value)
            }}
            value={date}
          />
          <input
            type="text"
            onChange={(event) => {
              setStudent(event.target.value)
            }}
            value={student}
            placeholder="Student ID"
          />
          <button
            onClick={async () => {
              if (date === '') {
                alert('Date is required.')
                return
              }
              if (student === '') {
                alert('Student ID is required.')
                return
              }
              if (auth) {
                await createRequest(date, student, auth)
                await fetchData()
              }
              setStudent('')
              setDate('')
              setCreating(false)
            }}
          >
            Submit
          </button>
          <button
            onClick={async () => {
              setStudent('')
              setDate('')
              setCreating(false)
            }}
          >
            Cancel
          </button>
        </>
      ) : (
        <button
          onClick={() => {
            setCreating(true)
          }}
        >
          Create New Request
        </button>
      )}

      <br />
      <button
        onClick={() => {
          history.push('/teacher/scan')
        }}
      >
        Back to Scanning View
      </button>
    </div>
  )
}

export default TeacherRequests
