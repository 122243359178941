import { createContext, Dispatch } from 'react'
import { getAuthData } from './api'

export type Store = {
  auth: Auth | false
  shouldScan: boolean
  running: boolean | undefined
}

export type Auth = { token: string; username: string; decoded: any }

export enum ActionType {
  SET_AUTH,
  INIT_QUAGGA,
  RUN_QUAGGA,
  STOP_QUAGGA,
  SET_SCANNING,
  NOT_SCANNING,
}

export type Action =
  | setAuth
  | initQuagga
  | runQuagga
  | stopQuagga
  | shouldScan
  | shouldNotScan

type setAuth = {
  type: ActionType.SET_AUTH
  payload: Auth | false
}

type initQuagga = {
  type: ActionType.INIT_QUAGGA
}

type runQuagga = {
  type: ActionType.RUN_QUAGGA
}

type stopQuagga = {
  type: ActionType.STOP_QUAGGA
}

type shouldScan = {
  type: ActionType.SET_SCANNING
}

type shouldNotScan = {
  type: ActionType.NOT_SCANNING
}

export type Context = Store & {
  dispatch: Dispatch<Action>
}

export const reducer = (store: Store, action: Action): Store => {
  switch (action.type) {
    case ActionType.SET_AUTH:
      return { ...store, auth: action.payload }
    case ActionType.INIT_QUAGGA:
      return { ...store, running: undefined }
    case ActionType.RUN_QUAGGA:
      return { ...store, running: true }
    case ActionType.STOP_QUAGGA:
      return { ...store, running: false }
    case ActionType.SET_SCANNING:
      return { ...store, shouldScan: true }
    case ActionType.NOT_SCANNING:
      return { ...store, shouldScan: false }
  }
}

export const initialStore: Store = {
  auth: getAuthData(),
  running: false,
  shouldScan: false,
}

export const storeContext = createContext<Context>({
  ...initialStore,
  dispatch: () => {},
})
