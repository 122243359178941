import { useState } from 'react'
import { vibrate } from '../../util'
import './index.scss'

const Button = ({
  content,
  action,
  disabled,
  green,
}: {
  content: any
  action: () => void
  disabled: boolean
  green?: boolean
}) => {
  const [mobile, setMobile] = useState(false)
  const [pressed, setPressed] = useState(false)

  return (
    <button
      onTouchStart={
        !disabled
          ? () => {
              vibrate()
              setPressed(true)
              action()
              setMobile(true)
            }
          : () => {}
      }
      onMouseDown={
        !disabled && !mobile
          ? () => {
              action()
            }
          : () => {}
      }
      onTouchEnd={(e) => {
        setPressed(false)
        e.preventDefault()
      }}
      onMouseUp={() => {
        setPressed(false)
      }}
      disabled={disabled}
      className={`button${green ? ' green' : ''}${pressed ? ' active' : ''}`}
    >
      {content}
    </button>
  )
}

export default Button
