/*
For now, just take in a room code (can be called username) (no constraints) and a passkey (no contraints)
this should only need to be called when first logging in.
if it's good, just return true.
if there's an auth error, return false
if there's some other error throw an error.
*/

import { Auth } from '../../store'

import jwtDecode from 'jwt-decode'

const baseURL = process.env.REACT_APP_BASE_URL

const signIn = async (
  roomCode: string,
  passKey: string,
): Promise<Auth | false> => {
  try {
    const response = await fetch(`${baseURL}/auth`, {
      method: 'POST',
      body: JSON.stringify({ username: roomCode, password: passKey }),
      headers: { 'Content-Type': 'application/json' },
    })
    if (response.ok) {
      const body = await response.json()
      const auth = {
        token: body.token,
        username: roomCode,
        decoded: jwtDecode(body.token),
      }
      sessionStorage.setItem('auth', JSON.stringify(auth))
      return auth
    } else if (response.status === 401) {
      return false
    } else {
      throw await response.text()
    }
  } catch (err) {
    console.error(err)
    throw err
  }
}

//returns the auth data or false.

// use a custom event to let the rest of the app know whether auth is true or false. I like that approach because it's flexible.

//returns the auth data or false.
//will handle reauthenticating and dispatching of auth events if applicable.
//different scenarios
/*
1. Get app for the first time, not logged in
2. Log in for first time
3. Return after leaving, still authenticated
4. Return after leaving, not authenticated (same as #1)
5. Return after leaving, auth timed out (will either reauthenticate or do #1)
6. Auth times out while using app (this is the hardest to handle. need to detect the problem while/before calling API, and either reauthenticate or ask to sign in again)
7. Sign out manually

Need app to handle what it would need if there was an auth token and a refresh token, both with timeout.
the auth object should keep track of this itself.

The get auth data will return the auth object if it can be successfully refreshed
or false if it cannot. this will handle case 1, 3, 4, 5, 6
need a better way to store the state of what is logged in. getAuthData could update state every time it is called but this would require it to know about the root object.
hmm... that's a good question.
sign in will handle case 2. should change authenticated to true
sign ouut will handle case 7. should change authenticated to false
the get auth data will also dispatch an auth event?
*/

const getAuthData = (): Auth | false => {
  const authString = sessionStorage.getItem('auth')
  if (authString) {
    const auth: Auth = JSON.parse(authString)
    if (auth.decoded.exp < Date.now() / 1000) {
      sessionStorage.removeItem('auth')
      return false
    }
    return auth
  } else {
    return false
  }
}

//returns nothing, just will dispatch an auth event and invalidate the auth data if applicable.

const signOut = () => {
  sessionStorage.removeItem('auth')
}

export { signIn, getAuthData, signOut }
