import { useEffect, useRef, useContext, useState } from 'react'
import Quagga, { QuaggaJSResultCallbackFunction } from '@ericblade/quagga2'
import { vibrate } from '../../util'
import { ActionType, storeContext } from '../../store'
import './index.scss'

const Scanner = ({
  scan,
  canScan,
}: {
  scan: (result: string) => void
  canScan: boolean
}) => {
  const [working, setWorking] = useState(true)
  const ref = useRef<QuaggaJSResultCallbackFunction>(() => {})
  const { dispatch, running, shouldScan } = useContext(storeContext)

  useEffect(() => {
    if (shouldScan && running === false && working) {
      dispatch({ type: ActionType.INIT_QUAGGA })
      Quagga.init(
        {
          inputStream: {
            type: 'LiveStream',
            constraints: {
              width: 1280, //get some more good resolution
              height: 720,
              facingMode: 'environment', // or user
            },
            target: '#ctx',
          },
          locator: {
            patchSize: 'small',
            halfSample: true,
          },
          numOfWorkers: 2,
          frequency: 10,
          decoder: {
            readers: [
              {
                format: 'code_39_reader',
                config: {
                  supplements: [],
                },
              },
            ],
            multiple: false,
          },
          locate: true,
          debug: true,
        },
        (err) => {
          if (err) {
            console.error(err)
            dispatch({ type: ActionType.STOP_QUAGGA })
            setWorking(false)
            //Display some sort of helpful error message or just don't show the scanner at all.
            //need to handle non-https better
          } else {
            dispatch({ type: ActionType.RUN_QUAGGA })
            Quagga.start()
          }
        },
      )
    }
  }, [shouldScan, running, working, dispatch])

  useEffect(() => {
    const stuff: QuaggaJSResultCallbackFunction = (result) => {
      ref.current(result)
    }

    Quagga.onDetected(stuff)
    return () => {
      Quagga.offDetected(stuff)
    }
  }, [])

  useEffect(() => {
    ref.current = (result) => {
      if (result && result.codeResult.code && canScan) {
        console.log(result.codeResult.code)
        if (canScan) {
          vibrate()
          scan(result.codeResult.code)
        }
      }
    }
    if (canScan) {
      dispatch({ type: ActionType.SET_SCANNING })
    }
    return () => {
      dispatch({ type: ActionType.NOT_SCANNING })
    }
  }, [canScan, scan, dispatch])
  const reload = () => {
    window.location.href = ''
  }

  return working !== false ? (
    <div id="ctx" className={canScan ? '' : 'hidden'}></div>
  ) : (
    <div id="ctx" className={canScan ? '' : 'hidden'} onClick={reload}>
      Camera not found or disabled. Click to try again, or just use the keypad
      for now.
    </div>
  )
}

export default Scanner
