import { useContext, useState } from 'react'
import { signIn } from '../../api'
import { ActionType, storeContext } from '../../store'

const Login = () => {
  const { dispatch } = useContext(storeContext)
  const [password, setPassword] = useState('')
  const [room, setRoom] = useState('')
  const [loading, setLoading] = useState(false)
  return (
    <div>
      <div style={{ fontSize: '10vh', width: '100%', textAlign: 'center' }}>
        Cavetime
      </div>
      <form>
        <input
          type="text"
          style={{ fontSize: '5vh', width: '100%', boxSizing: 'border-box' }}
          placeholder="Room Name"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          onChange={(event) => {
            setRoom(event.target.value)
          }}
          disabled={loading}
          value={room}
        />
        <input
          type="password"
          style={{ fontSize: '5vh', width: '100%', boxSizing: 'border-box' }}
          placeholder="Password"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          value={password}
          disabled={loading}
          onChange={(event) => {
            setPassword(event.target.value)
          }}
        />
        <input
          type="submit"
          onClick={async (event) => {
            event.preventDefault()
            if (!loading) {
              setLoading(true)
              try {
                const status = await signIn(room, password)
                if (!status) {
                  alert('Invalid room name or password.')
                  setPassword('')
                  setRoom('')
                } else {
                  setLoading(false)
                  dispatch({
                    type: ActionType.SET_AUTH,
                    payload: status,
                  })
                  return
                }
              } catch (error) {
                alert(`Error logging in: ${error}`)
              }
              setLoading(false)
            }
          }}
          style={{
            width: '100%',
            backgroundColor: 'red',
            color: 'white',
            fontSize: '10vh',
            border: 'none',
            borderRadius: '0px',
          }}
          disabled={loading}
          value="Login"
        />
      </form>
    </div>
  )
}
export default Login
