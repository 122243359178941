import { useReducer, useEffect, useState } from 'react'
import { TeacherScan, TeacherRequests, Login, PrivateRoute, PublicRoute } from './views'
import Helmet from 'react-helmet'
import Quagga from '@ericblade/quagga2'
import { BrowserRouter, Redirect, Route } from 'react-router-dom'
import { initialStore, storeContext, reducer, ActionType } from './store'

const App = () => {
  const [store, dispatch] = useReducer(reducer, initialStore)
  const [iOSUnder13, setiOSUnder13] = useState(false)

  useEffect(() => {
    const audio = new Audio() // temporary Audio object
    audio.volume = 0.5 // has no effect on iOS <= 12
    if (audio.volume === 1 && /^iP/.test(navigator.platform)) {
      setiOSUnder13(true)
    }
  }, [])

  useEffect(() => {
    if (store.running && !store.shouldScan) {
      Quagga.stop()
      dispatch({ type: ActionType.STOP_QUAGGA })
    }
  })

  return (
    <storeContext.Provider value={{ ...store, dispatch }}>
      <Helmet>
        <link
          rel="manifest"
          href={`${process.env.PUBLIC_URL}/manifest${
            iOSUnder13 ? '-ios12' : ''
          }.json`}
        />
      </Helmet>
      <BrowserRouter>
        <PublicRoute exact path="/">
          <Login />
        </PublicRoute>
        <Route exact path="/teacher">
          <Redirect to="/teacher/scan" />
        </Route>
        <PrivateRoute exact path="/teacher/scan">
          <TeacherScan />
        </PrivateRoute>
        <PrivateRoute exact path="/teacher/requests">
          <TeacherRequests />
        </PrivateRoute>
      </BrowserRouter>
    </storeContext.Provider>
  )
}

export default App
