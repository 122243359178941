import TeacherScan from './teacher/scan'
import TeacherRequests from './teacher/requests'
import Login from './login'
import { useContext } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { storeContext } from '../store'

const PrivateRoute = ({ children, ...props }: RouteProps) => {
  const { auth } = useContext(storeContext)
  return <Route {...props}>{auth ? children : <Redirect to="/" />}</Route>
}

const PublicRoute = ({ children, ...props }: RouteProps) => {
  const { auth } = useContext(storeContext)
  return (
    <Route {...props}>
      {!auth ? (
        children
      ) : (
        <Redirect to={`/${auth.decoded.role || 'teacher'}`} />
      )}
    </Route>
  )
}

export { TeacherScan, TeacherRequests, Login, PrivateRoute, PublicRoute }
