const Status = ({
  roomKey,
  signOut,
  requests,
}: {
  roomKey: string
  signOut: () => void
  requests: () => void
}) => {
  return (
    <div
      style={{
        width: '100%',
        backgroundColor: 'red',
        height: '5%',
        fontSize: '3vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0px',
      }}
    >
      <div
        style={{
          flexShrink: 1,
          minWidth: '0',
          textOverflow: 'hidden',
          whiteSpace: 'nowrap',
          height: '100%',
        }}
      >
        <div style={{ display: 'inline-block', paddingRight: '10px' }}>
          &nbsp;&nbsp;Logged in as {roomKey}
        </div>
        <button
          onClick={() => {
            requests()
          }}
          style={{
            paddingRight: '10px',
            paddingLeft: '10px',
            height: '100%',
            fontSize: '3vh',
            WebkitAppearance: 'none',
            border: 'none',
            backgroundColor: 'white',
            margin: '0px',
          }}
        >
          Requests
        </button>
      </div>
      <button
        onClick={() => {
          signOut()
        }}
        style={{
          paddingRight: '10px',
          paddingLeft: '10px',
          height: '100%',
          fontSize: '3vh',
          WebkitAppearance: 'none',
          border: 'none',
          backgroundColor: 'white',
          margin: '0px',
        }}
      >
        Logout
      </button>
    </div>
  )
}

export default Status
