const Preview = ({
  number,
  name,
  picture,
  setPicture,
  status,
  statusText,
  cursor,
  display,
}: {
  number: string
  name: string
  picture: string
  setPicture: (picture: string) => void
  status: boolean
  statusText: string
  cursor: boolean
  display: boolean
}) => {
  return cursor ? (
    <div
      style={{
        fontSize: '20vmin',
        width: '100%',
        textAlign: 'right',
        height: '24%',
        display: display ? 'block' : 'none',
      }}
    >
      {number === '' ? '' : number.substr(0, number.length - 1)}
      <span style={{ textDecoration: cursor ? 'underline' : 'none' }}>
        {number !== '' ? number.substr(-1) : '\u00A0\u00A0'}
      </span>
    </div>
  ) : (
    <>
      <div
        style={{
          width: '100%',
          fontSize: 0,
          height: '35%',
          display: 'flex',
        }}
      >
        <div
          style={{
            width: '40%',
            //display: 'flex',
            backgroundColor: 'black',
            //justifyContent: 'center',
            //alignItems: 'center',
            height: '100%',
          }}
        >
          <img
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
            src={picture}
            alt={''}
            onError={() => {
              setPicture(`${process.env.PUBLIC_URL}/logo512.png`)
            }}
          />
        </div>
        <div
          style={{
            width: '60%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              fontSize: '10vmin',
            }}
          >
            {name}
          </div>
          <div
            style={{
              fontSize: '10vmin',
            }}
          >
            {number}
          </div>
        </div>
      </div>
      <div
        style={{
          fontSize: '10vmin',
          backgroundColor: status ? '#0d0' : '#d00',
          color: 'white',
          height: '16%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <span>{statusText}</span>
      </div>
    </>
  )
}

export default Preview
