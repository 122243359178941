import { useState, useEffect, useCallback, useContext } from 'react'
import { Keypad, Preview, Scanner, Status } from '../../components'
import { useHistory } from 'react-router-dom'
import {
  fetchData,
  checkin,
  validate,
  characterCutoff,
  signOut,
} from '../../api'
import { ActionType, storeContext } from '../../store'

const TeacherScan = () => {
  const [number, setNumber] = useState('')
  const [name, setName] = useState('')
  const [picture, setPicture] = useState('')
  const [status, setStatus] = useState(false)
  const [statusText, setStatusText] = useState('')
  const [statusToken, setStatusToken] = useState<string | undefined>(undefined)
  const { auth, dispatch } = useContext(storeContext)
  const [loading, setLoading] = useState(false)

  const history = useHistory()

  const clear = () => {
    setNumber('')
    setName('')
    setPicture('')
    setStatusToken('')
  }

  const input = useCallback(
    (digit) => {
      if (name === '') {
        if (number.length < characterCutoff) {
          setNumber(number + digit.toString())
        }
      } else {
        clear()
        setNumber(digit.toString())
      }
    },
    [number, name],
  )

  const backspace = useCallback(() => {
    if (number.length > 0 && name === '') {
      setNumber(number.substr(0, number.length - 1))
    } else if (name !== '') {
      clear()
    }
  }, [number, name])

  const getInfo = useCallback(async (number, authData) => {
    setLoading(true)
    //Fetch data from API, record to attendance...
    //Maybe fetch from attendance beforehand?
    //Details that we need - Student Number, Location (Teacher?)
    if (number !== undefined) {
      if (validate(number)) {
        try {
          const { name, photo, status, statusText, statusToken } =
            await fetchData(number, authData)
          setName(name)
          setPicture(photo)
          setStatus(status)
          setStatusText(statusText)
          setStatusToken(statusToken)
        } catch (error) {
          //There was an error with fetching. Alert the user.
          alert(`Error getting grades for ${number}: ${error}`)
        }
      }
    }
    setLoading(false)
  }, [])

  const scan = useCallback(
    (number) => {
      if (validate(number) && !loading) {
        setNumber(number)
        getInfo(number, auth)
      }
    },
    [auth, getInfo, loading],
  )

  const submitConfirm = useCallback(async (number, statusToken, authData) => {
    setLoading(true)
    try {
      await checkin(number, statusToken, authData)
    } catch (error) {
      //There was an error with fetching. Alert the user.
      alert(`Error confirming checkin for ${number}: ${error}`)
    }
    setLoading(false)
    clear()
  }, [])
  const confirm = useCallback(() => {
    if (!loading) {
      if (name === '') {
        getInfo(number, auth)
      } else {
        submitConfirm(number, statusToken, auth)
      }
    }
  }, [number, name, getInfo, submitConfirm, loading, statusToken, auth])

  useEffect(() => {
    const temp = (event: KeyboardEvent) => {
      if (event) {
        if (!loading) {
          switch (event.keyCode) {
            //do this better by using some sort of key value map
            case 48:
            case 96:
              input(0)
              break
            case 49:
            case 97:
              input(1)
              break
            case 50:
            case 98:
              input(2)
              break
            case 51:
            case 99:
              input(3)
              break
            case 52:
            case 100:
              input(4)
              break
            case 53:
            case 101:
              input(5)
              break
            case 54:
            case 102:
              input(6)
              break
            case 55:
            case 103:
              input(7)
              break
            case 56:
            case 104:
              input(8)
              break
            case 57:
            case 105:
              input(9)
              break
            case 13:
              confirm()
              break
            case 8:
            case 46:
              backspace()
              break
            //todo: find more keycodes
            default:
              break
          }
        }
      }
    }
    document.addEventListener('keydown', temp)
    return () => {
      document.removeEventListener('keydown', temp)
    }
  }, [backspace, input, confirm, loading])
  return (
    <>
      <Status
        roomKey={auth ? auth.username : ''}
        signOut={() => {
          signOut()
          dispatch({ type: ActionType.SET_AUTH, payload: false })
        }}
        requests={() => {
          history.push('/teacher/requests')
        }}
      />
      <Preview
        number={number}
        name={name}
        picture={picture}
        setPicture={setPicture}
        status={status}
        statusText={statusText}
        cursor={name === ''}
        display={number !== ''}
      />
      <Scanner scan={scan} canScan={!loading && number === ''} />
      <Keypad
        input={input}
        backspace={backspace}
        confirm={confirm}
        canClear={!loading && name !== ''}
        canBackspace={!loading && number.length > 0}
        canProceed={!loading && validate(number) && name === ''}
        canConfirm={!loading && name !== '' && !!statusToken}
        canInput={!loading && number.length < characterCutoff && name === ''}
      />
    </>
  )
}

export default TeacherScan
